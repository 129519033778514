<template>
	<div>
		<div class="flex">
			<div class="md:w-4/5 xl:w-3/5 mx-auto">

				<h1 class="text-2xl font-bold mb-5">Update Company Profile</h1>

				<form @submit.prevent="submit" novalidate class="card border border-blue-200 pt-16 pb-10 lg:px-10 xl:px-16" key="admin">
					
					<form-group leftIcon="business-outline" :value="companyName" disabled :loading="loadingProfile">
						Company Name
					</form-group>

					<div class="grid grid-cols-2 gap-8">

						<div class="col-span-1">
							<form-group
								leftIcon="briefcase-outline"
								name="no_of_exec"
								v-model="form.data.no_of_exec.value"
								:form="form"
								:disabled="!canEdit"
								:loading="loadingProfile"
							>
								No. of Executives
							</form-group>
						</div>

						<div class="col-span-1">
							<form-group
								leftIcon="person-outline"
								name="no_of_staff"
								v-model="form.data.no_of_staff.value"
								:form="form"
								:disabled="!canEdit"
								:loading="loadingProfile"
							>
								No. of Staff
							</form-group>
						</div>

					</div>

					<form-group
						leftIcon="map-outline"
						name="company_address"
						v-model="form.data.company_address.value"
						:form="form"
						:disabled="!canEdit"
						:loading="loadingProfile"
					>
						Company Address
					</form-group>

					<form-group
						leftIcon="globe-outline"
						name="company_website"
						v-model="form.data.company_website.value"
						:form="form"
						:disabled="!canEdit"
						:loading="loadingProfile"
					>
						Company Website
					</form-group>

					<div class="grid grid-cols-2 gap-8">

						<div class="col-span-1">
							<form-group
								type="select"
								:options="daysOfTheMonth"
								select-display-type="grid"
								:select-grid-columns="5"
								leftIcon="calendar-outline"
								name="salary_day"
								v-model="form.data.salary_day.value"
								:form="form"
								:disabled="!canEdit"
								:loading="loadingProfile"
							>
								Salary Day
							</form-group>
						</div>

						<!-- <div class="col-span-1">
							<form-group
								type="select" :options="loan_types" leftIcon="help-buoy-outline"
								name="salary_advance_type"
								v-model="form.data.salary_advance_type.value"
								:form="form"
								:disabled="!canEdit"
								:loading="loadingProfile"
							>
								Current Loan Type
							</form-group>
						</div> -->
						
					</div>

					<div class="text-right">
						<button type="submit" class="button bg-blue-500 hover:bg-blue-600 text-white" :disabled="form.loading || !canEdit">
							<template v-if="form.loading">Submitting...</template>
							<template v-else>Submit Profile</template>
						</button>
					</div>

				</form>

			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Update your company's profile!
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Update your company's profile by filling the form.</p>
					<p class="mb-2">This information will be used to verify your company's account and get your loans approved.</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Company information successfully updated.
			</div>

			<router-link :to="{ name: 'dashboard' }" class="button bg-blue-500 hover:bg-blue-600 text-white">
				Go to Dashboard
			</router-link>
			
		</modal>

		<!-- <div class="modal">
			<div class="modal-content w-2/5 xl:w-3/10 text-center pt-24">
			</div>
		</div> -->

	</div>
</template>

<script>
	export default {
		data() {
			return {
				checkmark: require('@/assets/checkmark-base.svg'),
				company: null,
				form: this.$options.basicForm([
					{name: 'no_of_exec', rules: 'required|number'},
					{name: 'no_of_staff', rules: 'required|number'},
					{name: 'company_address', rules: 'required|min:10'},
					{name: 'company_website', rules: 'required'},
					{name: 'salary_day', rules: 'required'},
					// {name: 'salary_advance_type', rules: 'required'},
				]),
				loan_types: [
					{
						title: 'None',
						value: 'none'
					},
					{
						title: 'Salary Advance',
						value: 'salary_advance'
					},
					{
						title: 'Car',
						value: 'car'
					},
					{
						title: 'Housing',
						value: 'housing'
					},
					{
						title: 'Emergency',
						value: 'emergency'
					},
					{
						title: 'Health',
						value: 'health'
					},
					{
						title: 'Education',
						value: 'education'
					}
				],
				loadingProfile: null,
				modalKey: 'settings-company-profile-help',
			}
		},
		computed: {
			canEdit() {
				return this.canDo('can_update_company_profile');
			},
			companyName() {
				return this.company?.name;
			},
			daysOfTheMonth() {
				const days = [];

				for (var i = 0; i < 31; i++) {
					const day = i+1;
					const value = day;
					const title = day.toPosition();
					days.push({ title, value });
				}

				return days;
			}
		},
		beforeMount() {
			this.loadCompanyProfile();
		},
		mounted() {
			this.openModal(this.modalKey, this.$refs.helpModal);
		},
		methods: {
			async loadCompanyProfile() {
				this.loadingProfile = true;

				await this.$get({
					url: `${this.$baseurl}/companies/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.company = response.data.company;
						this.mapProfileData();
					},
					error: () => {

					}
				});

				this.loadingProfile = false;
			},
			mapProfileData() {
				for (let key in this.form.data) {
					this.form.data[key].value = this.company?.profile?.[key];
				}
			},
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.loading = true;

				await this.$put({
					url: `${this.$baseurl}/companies/profile/update/${this.user.company_id}`,
					data: JSON.stringify({
						...this.getFormData(),
						company_id: this.user.company_id
					}),
					headers: {
						...this.headers,
						'Content-Type': 'application/json'
					},
					success: (response) => {
						this.$refs.modal.open();

						if (this.company) {
							this.company = response.data.company;
							this.$store.commit('session/updateUser', {
								company: response.data.company
							});
							this.$store.dispatch('session/saveUser');
						}
					},
					error: (error) => {
						this.form.error = error;
						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			},
			success() {
				this.$refs.modal.open();
			}
		}
	}
</script>